import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { useTheme } from '@mui/material/styles';

const ScheduleDate = ({ getTime, closePeriod }) => {
   const now = dayjs();
   const [dateTime, setDateTime] = React.useState(now);

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is small

   const handleDateTimeChange = (newValue) => {
      if (newValue && newValue.isBefore(now)) {
         setDateTime(now);
      } else {
         setDateTime(newValue);
      }
   };

   return (
      <Card sx={{ minHeight: '200px', p: { xs: '1rem', sm: '1.5rem 2rem' } }}>
         <Box display="flex" justifyContent="center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <StaticDateTimePicker
                  value={dateTime}
                  onChange={handleDateTimeChange}
                  minDateTime={now}
                  slotProps={{
                     actionBar: { actions: [] }, // Hides built-in "OK" & "Cancel" buttons
                  }}
               />
            </LocalizationProvider>
         </Box>

         <Box
            sx={{
               mt: '1rem',
               display: 'flex',
               flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons on mobile
               alignItems: 'center',
               justifyContent: 'flex-end',
               gap: '10px',
            }}
         >
            <Typography
               sx={{
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  fontWeight: 600,
                  color: '#374DA0',
                  cursor: 'pointer',
                  padding: '8px 16px',
                  borderRadius: '6px',
                  transition: 'background 0.3s ease',
                  '&:hover': { background: '#dfe3f8' },
                  width: isMobile ? '100%' : 'auto', // Full width button on mobile
                  textAlign: 'center',
               }}
               onClick={() => closePeriod()}
            >
               Cancel
            </Typography>
            <Typography
               sx={{
                  fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                  fontWeight: 600,
                  color: 'white',
                  cursor: 'pointer',
                  background: '#374DA0',
                  padding: '8px 16px',
                  borderRadius: '6px',
                  transition: 'background 0.3s ease',
                  '&:hover': { background: '#2c3a8d' },
                  width: isMobile ? '100%' : 'auto', // Full width button on mobile
                  textAlign: 'center',
               }}
               onClick={() => getTime(dateTime.toISOString())}
            >
               Confirm
            </Typography>
         </Box>
      </Card>
   );
};

export default ScheduleDate;
