import httpclient from '../httpClient.js';
import { getBaseUrls, userLSAuth } from '../env.js';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import setAuthToken from '../../../utils/setAuthToken.js';
import { toast } from 'react-toastify';

const initialState = {
   loading: 'idle',
   admin: userLSAuth(),
   paymentData: [],
   rangedPaymentData: [],
   lastFourpaymentData: [],
   amount: 0,
   error: null,
   timeFrame: null,
   paginate: 0,
   amountConv: 0,
   unitsConv: 0,
   paystack: null,
   flutterwave: null,
   opay: null,
   activities: [],
   paginatedActivities: [],
   bankDetails: [],
   hasMore: true,
   receipt: {
      total: 0,
      subtotal: 0,
      transactionFee: 0,
      vat: 0,
      discount: 0,
   },
   isPreview: false,
};

export const getUserTransactions = createAsyncThunk(
   'get/transactions',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/api/v1/transaction/${
               payload.userId ? payload.userId : userLSAuth().id
            }?page=${payload.page}&take=50&startDate=${
               payload.timeFrame.start
            }&EndDate=${payload.timeFrame.end}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const getRangedUserTransactions = createAsyncThunk(
   'get/rangedTransactions',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/api/v1/transaction/${
               payload.userId ? payload.userId : userLSAuth().id
            }?page=${payload.page}&take=50&startDate=${
               payload.timeFrame.start
            }&EndDate=${payload.timeFrame.end}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const getLastFour = createAsyncThunk(
   'get/lastFour',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/api/v1/transaction/${
               payload.userId ? payload.userId : userLSAuth().id
            }?page=${payload.page}&take=4&startDate=${
               payload.timeFrame.start
            }&EndDate=${payload.timeFrame.end}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// calc your top up in amount to units
export const getAmountConv = createAsyncThunk(
   'get/amountConv',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${
               getBaseUrls().external
            }/api/v1/calculator/topup-with-amount?amount=${payload.event}`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// calc your top up in amount to units
export const getUnitsConv = createAsyncThunk(
   'get/unitsConv',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().external}/api/v1/calculator/topup-with-unit?unit=${
               payload.event
            }`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

// top up with paystack
export const topUpPayStack = createAsyncThunk(
   'topup/paystack',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().external}/api/v1/payment/paystack/${
               userLSAuth().id
            }`,
            {
               ...payload,
               currency: 'NGN',
               redirectUrl: `${getBaseUrls().app}/top-up`,
            },
            config
         );

         if (response.status === 200 || response.data.code === 200) {
            return response.data;
         } else if (response.data.code === 400 || response.data.code === 401) {
            toast.error(response.data.message);
         } else {
            toast.error('An error occured, please try again!!!');
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log('got here');
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// top up with flutterwave
export const topUpFlutter = createAsyncThunk(
   'topup/flutterwave',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().external}/api/v1/payment/flutterwave/${
               userLSAuth().id
            }`,
            {
               ...payload,
               currency: 'NGN',
               redirectUrl: `${getBaseUrls().app}/top-up`,
            },
            config
         );

         if (response.status === 200 || response.data.code === 200) {
            return response.data;
         } else if (response.data.code === 400 || response.data.code === 401) {
            toast.error(response.data.message);
         } else {
            toast.error('An error occured, please try again!!!');
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// top up with opay
export const topUpOpay = createAsyncThunk(
   'topup/opay',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().external}/api/v1/payment/opay/${userLSAuth().id}`,
            {
               amount: payload.amount,
               currency: 'NGN',
               redirectUrl: window.location.hostname.includes('localhost')
                  ? 'http://localhost:5173/top-up'
                  : `${getBaseUrls().app}/top-up`,
            },
            config
         );

         if (response.data.code === 200) {
            return response.data.data;
         } else if (response.data.code === 400 || response.data.code === 401) {
            toast.error(response.data.message);
         } else {
            toast.error('An error occured, please try again!!!');
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// top up with bank transfer
export const topUpBank = createAsyncThunk(
   'topup/bank',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().external}/api/v1/payment/bank-transfer/${
               userLSAuth().id
            }`,
            {
               amount: payload.amount,
            },
            config
         );

         if (response.data.code === 200) {
            return response.data.data;
         } else if (response.data.code === 400 || response.data.code === 401) {
            toast.error(response.data.message);
         } else {
            toast.error('An error occured, please try again!!!');
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

// get user activity
export const getActivities = createAsyncThunk(
   'get/activities',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/api/v1/activity/${
               userLSAuth().id
            }?page=1&take=10`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);
// get paginated user activity
export const getPaginatedActivities = createAsyncThunk(
   'get/paginated_activities',
   async (payload, { rejectWithValue }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/api/v1/activity/${userLSAuth().id}?page=${
               payload.page
            }&take=50`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const transactionSlice = createSlice({
   name: 'transaction',
   initialState,
   reducers: {
      setTimeFrame: (state, action) => {
         state.timeFrame = action.payload;
      },
      amountPayable: (state, action) => {
         state.amount = action.payload.amount;
         state.amountConv = 0;
         state.unitsConv = 0;
      },
      resetPreview: (state, action) => {
         state.isPreview = false;
         state.receipt = {
            total: 0,
            subtotal: 0,
            transactionFee: 0,
            vat: 0,
            discount: 0,
         };
         state.paystack = null;
         state.flutterwave = null;
         state.opay = null;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getUserTransactions.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getUserTransactions.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;
            state.rangedPaymentData = [];

            if (
               state.paymentData.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the paymentData
               const filteredData = newData.filter(
                  (item) =>
                     !state.paymentData.some((data) => data.id === item.id)
               );
               state.paymentData = [...state.paymentData, ...filteredData];
            }
            state.loading = 'success';
         })
         .addCase(getUserTransactions.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getRangedUserTransactions.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getRangedUserTransactions.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;
            state.paymentData = [];
            if (Number(action.payload.meta.page) === 1) {
               state.rangedPaymentData = [];
            }
            if (
               state.rangedPaymentData.length === state.paginate ||
               state.paginate === 0 ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the rangedPaymentData
               const filteredData = newData.filter(
                  (item) =>
                     !state.rangedPaymentData.some(
                        (data) => data.id === item.id
                     )
               );
               state.rangedPaymentData = [
                  ...state.rangedPaymentData,
                  ...filteredData,
               ];
               if (state.rangedPaymentData.length === state.paginate) {
                  state.hasMore = false;
               }
            }

            state.loading = 'success';
         })
         .addCase(getRangedUserTransactions.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getLastFour.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getLastFour.fulfilled, (state, action) => {
            state.lastFourpaymentData = action.payload.data;
            state.loading = 'success';
         })
         .addCase(getLastFour.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getAmountConv.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getAmountConv.fulfilled, (state, action) => {
            state.unitsConv = action.payload.data;
            state.loading = 'success';
         })
         .addCase(getAmountConv.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getUnitsConv.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getUnitsConv.fulfilled, (state, action) => {
            state.amountConv = action.payload.data;
            state.loading = 'success';
         })
         .addCase(getUnitsConv.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(topUpPayStack.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(topUpPayStack.fulfilled, (state, action) => {
            state.paystack = action.payload.data;
            state.receipt = action.payload.meta.receipt;
            state.isPreview = action.meta.arg.isPreview;
            state.loading = 'success';
         })
         .addCase(topUpPayStack.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(topUpFlutter.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(topUpFlutter.fulfilled, (state, action) => {
            state.flutterwave = action.payload.data;
            state.receipt = action.payload.meta.receipt;
            state.isPreview = action.meta.arg.isPreview;
            state.loading = 'success';
         })
         .addCase(topUpFlutter.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(topUpOpay.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(topUpOpay.fulfilled, (state, action) => {
            state.opay = action.payload;
            state.loading = 'success';
         })
         .addCase(topUpOpay.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(topUpBank.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(topUpBank.fulfilled, (state, action) => {
            state.bankDetails = action.payload;
            state.loading = 'success';
         })
         .addCase(topUpBank.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getActivities.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getActivities.fulfilled, (state, action) => {
            state.activities = action.payload.data;
            state.paginate = action.payload.meta.total;
         })
         .addCase(getActivities.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getPaginatedActivities.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getPaginatedActivities.fulfilled, (state, action) => {
            // state.paginatedActivities = action.payload.data;
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;
            if (
               state.paginatedActivities.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the paymentData
               newData.filter(
                  (item) =>
                     !state.paginatedActivities.some(
                        (data) => data.id === item.id
                     )
               );
               state.paginatedActivities = [
                  ...state.paginatedActivities,
                  ...newData,
               ];
            }
            state.loading = 'success';
         })
         .addCase(getPaginatedActivities.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         });
   },
});
export const { setTimeFrame, amountPayable, resetPreview } =
   transactionSlice.actions;
export default transactionSlice.reducer;
